<script setup>
import { defineProps, defineEmits, toRefs, ref, computed, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  data: Object,
  done: Object,
  pink: Boolean
});

const { data, done, pink } = toRefs(props);
const emit = defineEmits(['claim', 'PlayerDone']);
const claimClicked = ref(false);

const openInNewTab = (url) => {
  window.open(url, '_blank');
};

const claim = () => {
  if (!claimClicked.value) {
    emit('claim', data.value.ID);
    claimClicked.value = true; // Disable button after clicking
  }
};

const PlayerDone = () => emit('PlayerDone', data.value.ID);

// Ref to trigger reactivity
const currentTime = ref(getCurrentDateCET());

// Setup a timer to update the currentTime every second
let timer = null;

onMounted(() => {
  timer = setInterval(() => {
    currentTime.value = getCurrentDateCET();
  }, 1000); // Update every second
});

onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
  }
});

// Utilise Intl.DateTimeFormat pour garantir CET/CEST correct
function getCurrentDateCET() {
  return new Date();
}

const handleOpenTab = (value) => {
  const formattedValue = value.replace(/^0+/, ''); // Remove leading zeros
  openInNewTab(`/leaderboard/${formattedValue}`);
};

const timeRemaining = computed(() => {
  const now = currentTime.value;
  const formatter = new Intl.DateTimeFormat('fr-FR', {
    timeZone: 'Europe/Paris',
    weekday: 'long'
  });
  
  // Get current weekday in CEST
  const weekday = formatter.format(now);
  
  // If it's Monday (Lundi), challenge should be active
  if (weekday === 'lundi') {
    return "RELOAD THE PAGE";
  }
  
  // Calculate days until next Monday
  const currentDay = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    .indexOf(weekday);
  const daysUntilNextMonday = currentDay === 0 ? 1 : (8 - currentDay);
  
  // Create next Monday at midnight
  const nextMonday = new Date(now);
  nextMonday.setDate(nextMonday.getDate() + daysUntilNextMonday);
  nextMonday.setHours(0, 0, 0, 0);

  const diff = nextMonday - now;

  if (diff <= 0) {
    return "RELOAD THE PAGE";
  }

  const days = Math.floor(diff / (24 * 3600 * 1000));
  const hours = Math.floor((diff % (24 * 3600 * 1000)) / 3600000);
  const minutes = Math.floor((diff % 3600000) / 60000);
  const seconds = Math.floor((diff % 60000) / 1000);

  let result = 'ACTIVE IN ';
  if (days > 0) {
    result += `${days}D `;
  }
  if (hours > 0 || days > 0) {
    result += `${hours}H `;
  }
  result += `${minutes}M `;
  result += `${seconds}S`;

  return result.trim();
});

</script>



<template>
<v-card v-if="!pink" class="challenge_card" style="border-radius: 8px;">
    <div style="height: 158px;overflow:hidden;">
        <v-btn class="ma-2 PlayerDone" v-bind="props" icon="mdi-menu" @click="PlayerDone"></v-btn>
        <span class='ma-2 CID'>ID #{{data.ID}}</span>
        <img class="d-flex justify-center align-center" style="height: 142px;width: 264px;margin-left: auto; margin-right: auto; margin-top: 8px; border-radius: 4px;" :src="'https://cdn.nightriderz.world/images/website/challenges/'+data.image +''">
        <v-tooltip activator="parent" :text="'Image by ' + data.image_by" location="top"></v-tooltip>
    </div>
    <div class="playersdone" style="background-color: #1f2430; margin-left:8px; margin-right: 8px; border-radius: 4px;margin-bottom: 8px;">
        <div style="text-transform: uppercase; font-size: 11px; font-weight: 600; background: #11141c;text-align: center; border-radius: 2px;">
           <span style="text-align: center;">{{data.made_count }} players completed this challenge</span>
        </div>
    </div>
    <div class="conditions" style="background-color: #1f2430; margin-left:8px; margin-right: 8px; border-radius: 4px;">
        <div v-for="(condition, index) in data.conditions" :key="condition.type" :style="{ marginBottom: index === data.conditions.length - 1 ? '0px' : '6px' }" style="text-transform: uppercase; font-size: 12px; font-weight: 600; background: #11141c; border-radius: 2px;">
            <div v-if="condition.type == 'raceID'" @click="handleOpenTab(condition.value)" style="cursor: pointer;">
                <span :class="'icon-' + condition.race_type" style="margin-right: 15px; width: 56px; height: 56px; display: block; position: relative;">
                    <span :class="'class_' + condition.race_class"></span>
                </span>
                <span class="text">{{ condition.race_name }}</span>
                <div v-if="condition.value >= 5000 && condition.value < 6000">
                    <span style="margin-left: -180px;margin-top: 6px;font-size: 10px;font-weight: 200;">TIME-ATTACK MODE</span>
                </div>
                <div v-if="(condition.race_type == 9 || condition.race_type == 4) && (condition.value < 5000 || condition.value > 5999)">
                    <span style="margin-left: -180px;margin-top: 6px;font-size: 10px;font-weight: 200;">NORMAL MODE</span>
                </div>
            </div>
            <div v-else>
                <span :class="'icon-' + condition.type + ' ' + condition.type + '-' + condition.value" style="margin-right: 15px; width: 56px; height: 56px; display: block; position: relative;"></span>
                <span class="text">{{ condition.type == "pu" && condition.value == true ? "Powerups not allowed" : condition.value }}</span>
            </div>
        </div>
    </div>
    <button raised class="custom reward gras" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; margin-top:8px;border-radius: 4px;"><v-icon style="padding-right: 16px;margin-top:-2px">mdi-gift-outline</v-icon>{{data.reward}}</button>

    <template v-if="!pink">
    <div><button v-if="data.type == 'expired'" raised class="custom gras" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; border-radius: 4px; width: 220px;background: #1f2430;pointer-events: none;"><v-icon style="padding-right: 16px;margin-top:-2px">mdi-clock-remove-outline</v-icon>CHALLENGE EXPIRED</button><button v-if="done.done == 1 && data.type == 'expired'" raised class="custom gras gris" style="margin-bottom: 8px; border-radius: 4px;background: #00C853;width: 36px;pointer-events: none;"><v-icon style="margin-top:-2px;margin-left: -8px;">mdi-check-circle-outline</v-icon></button><button @click="claim" :disabled="claimClicked" v-if="done.done == 0 && data.type == 'expired'" raised class="custom gras gris" style="margin-bottom: 8px; border-radius: 4px;background: #0288D1;width: 36px;"><v-icon style="margin-top:-2px;margin-left: -8px;">mdi-alert-circle-outline</v-icon></button><button v-if="done.done == 'Expired' && data.type == 'expired'" raised class="custom gras gris" style="margin-bottom: 8px; border-radius: 4px;background: #E53935;width: 36px;pointer-events: none;"><v-icon style="margin-top:-2px;margin-left: -8px;">mdi-close-circle-outline</v-icon></button></div>
        <button v-if="data.type == 'next'" raised class="custom gras" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; border-radius: 4px;pointer-events: none;"><v-icon style="padding-right: 16px;margin-top:-2px">mdi-clock-time-two-outline</v-icon>{{ timeRemaining }}</button>
        <button v-else-if="done.done == null && data.type != 'expired'" raised class="custom gras red" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; border-radius: 4px;pointer-events: none;"><v-icon style="padding-right: 16px;margin-top:-2px;">mdi-close-circle-outline</v-icon>NOT COMPLETED</button>
        <button v-else-if="done.done == 0 && data.type != 'expired'" raised class="custom gras" @click="claim" :disabled="claimClicked" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; border-radius: 4px;background: #0288D1;"><v-icon style="padding-right: 16px;margin-top:-2px">mdi-alert-circle-outline</v-icon>CLAIM REWARD</button>
        <button v-else-if="done.done == 1 && data.type != 'expired'" raised class="custom gras gris" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; border-radius: 4px;background: #00C853;pointer-events: none;"><v-icon style="padding-right: 16px;margin-top:-2px;">mdi-check-circle-outline</v-icon>REWARD CLAIMED</button>
    </template>
</v-card>
<v-card v-if="pink" class="challenge_card" style="border-radius: 8px;">
    <div style="height: 158px;overflow:hidden;">
        <img class="d-flex justify-center align-center" style="height: 142px;width: 264px;margin-left: auto; margin-right: auto; margin-top: 8px; border-radius: 4px;" :src="'https://cdn.nightriderz.world/images/website/challenges/'+data.image +''">
        <v-tooltip activator="parent" :text="'Image by ' + data.image_by" location="top"></v-tooltip>
    </div>

    <div class="conditions" style="background-color: #1f2430; margin-left:8px; margin-right: 8px; border-radius: 4px;">
        <div v-for="(condition, index) in data.conditions" :key="condition.type" :style="{ marginBottom: index === data.conditions.length - 1 ? '0px' : '6px' }" style="text-transform: uppercase; font-size: 12px; font-weight: 600; background: #11141c; border-radius: 2px;">
            <div v-if="condition.type == 'raceID'">
                <span :class="'icon-' + condition.race_type" style="margin-right: 15px; width: 56px; height: 56px; display: block; position: relative;">
                    <span :class="'class_' + condition.race_class"></span>
                </span>
                <span class="text">{{ condition.race_name }}</span>
                <div v-if="condition.value >= 5000 && condition.value < 6000">
                    <span style="margin-left: -180px;margin-top: 6px;font-size: 10px;font-weight: 200;">TIME-ATTACK MODE</span>
                </div>
                <div v-if="(condition.race_type == 9 || condition.race_type == 4) && (condition.value < 5000 || condition.value > 5999)">
                    <span style="margin-left: -180px;margin-top: 6px;font-size: 10px;font-weight: 200;">NORMAL MODE</span>
                </div>
            </div>
            <div v-else>
                <span :class="'icon-' + condition.type + ' ' + condition.type + '-' + condition.value" style="margin-right: 15px; width: 56px; height: 56px; display: block; position: relative;"></span>
                <span class="text">{{ condition.type == "pu" && condition.value == true ? "Powerups not allowed" : condition.value }}</span>
            </div>
        </div>
    </div>
    <button raised class="custom reward gras" style="margin-right: 8px; margin-left: 8px; margin-bottom: 8px; margin-top:8px;border-radius: 4px;"><v-icon style="padding-right: 16px;margin-top:-2px">mdi-gift-outline</v-icon>{{data.reward}}</button>
</v-card>
</template>

<style scoped>
.PlayerDone {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 12px;
    height: 30px !important;
    width: 30px !important;
    background-color: #11141c;
}

.CID {
    position: absolute;
    right: 201px;
    top: 119px;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
    font-weight: 600;
    height: auto !important;
    width: 57px !important;
    background-color: #11141c;
}

.challenge_card {
    background: var(--primary-color);
    margin: 25px;
    width:280px;
}

.custom {
    margin: 0;
    border-radius: 0px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 36px;
    transition: 0.2s;
}

.gris {
    background-color: #424242;
}

.red {
    background: #E53935;
}

.vert:hover {
    background: #3a941fa9;
}

.reward {
    background: -webkit-linear-gradient(left, #FFC107, #cb8000, #FFAB00);
	background: linear-gradient(left, #FFC107, #cb8000, #FFAB00);
    pointer-events: none;
}

.vert {
    background: #3b941f;
}

.gras {
    font-weight: bold;
}

.gold {
    background: #e8bb07;
    pointer-events: none;
}

/* LEGACY */

.challenge_card {
    min-height: 20px;
    margin-bottom: 20px;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 3px 3px -2px rgb(0 0 0 / 20%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    overflow: hidden;
    background: #11141c;
    color: white;
    display: flex;
    flex-direction: column;
    height: initial;
}

.icon-eventDurationInMilliseconds {
    background: url('https://cdn.nightriderz.world/images/website/icons/timer.png');
    top: -3px;
}

.icon-raceID {
    background: url('https://cdn.nightriderz.world/images/website/icons/sprint.png');
    top: -5px;
}

.icon-car {
    background: url('https://cdn.nightriderz.world/images/website/icons/car.png');
    top: -2px;
}

.icon-copsDisabled {
    background: url('https://cdn.nightriderz.world/images/website/icons/copsDisabled.png');
    top: -5px;
}

.icon-reward {
    background: url('https://cdn.nightriderz.world/images/website/icons/reward.png');
    top: -5px;
}

.icon-pu {
    background: url('https://cdn.nightriderz.world/images/website/icons/powerup.png');
    top: -4px;
}

.icon-4 {
    background: url('https://cdn.nightriderz.world/images/website/icons/circuit.png');
    top: -5px;
}

.icon-9 {
    background: url('https://cdn.nightriderz.world/images/website/icons/sprint.png');
    top: -5px;
}

.icon-19 {
    background: url('https://cdn.nightriderz.world/images/website/icons/drag.png');
    top: -5px;
}

.icon-12 {
    background: url('https://cdn.nightriderz.world/images/website/icons/pursuit.png');
    top: -5px;
}

.icon-24 {
    background: url('https://cdn.nightriderz.world/images/website/icons/escape.png');
    top: -5px;
}

.icon-copsRammed {
    background: url('https://cdn.nightriderz.world/images/website/icons/copsRammed.png');
    top: -5px;
}

.icon-topSpeed {
    background: url('https://cdn.nightriderz.world/images/website/icons/speed.png');
    top: -3px;
}

.icon-costToState {
    background: url('https://cdn.nightriderz.world/images/website/icon-ingame/costtostate.png');
    top: -5px;
}

.icon-rank {
    background: url('https://cdn.nightriderz.world/images/website/icon-ingame/rank.png');
    top: -5px;
}

.class_A {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/A.png');
    background-size: cover
}

.class_B {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/B.png');
    background-size: cover
}

.class_C {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/C.png');
    background-size: cover
}

.class_D {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/D.png');
    background-size: cover
}

.class_E {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/E.png');
    background-size: cover
}

.class_S1 {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/S1.png');
    background-size: cover
}
.class_S2 {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/S2.png');
    background-size: cover
}
.class_X {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/X.png');
    background-size: cover
}
.class_O {
    background: url('https://cdn.nightriderz.world/images/website/moonset/classes/O.png');
    background-size: cover
}

.icon-raceID,
.icon-rank,
.icon-reward {
    display: block;
    width: 55px;
    height: 55px;
    background-size: cover;
    margin-right: 15px;
    position: relative;
}

.class_A,
.class_B,
.class_C,
.class_D,
.class_E,
.class_O,
.class_S1,
.class_S2,
.class_X {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
}

.conditions .text {
    display: block;
    width: 180px;
    margin: auto;
}

.conditions div {
    display: flex;
}

.conditions {
    padding-right: 6px;
    padding-left: 6px;
    padding-top:6px;
    padding-bottom:6px;
    position: relative;
    flex-grow: 1;
}

.playersdone {
    padding-right: 6px;
    padding-left: 6px;
    padding-top:6px;
    padding-bottom:6px;
}

.gglesmecs {
    position: absolute;
    background: grey;
    z-index: 1;
    overflow: auto;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 0%;
    transition: height 0.5s;
}

.gglesmecs-active {
    position: absolute;
    background: grey;
    z-index: 1;
    overflow: auto;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: height 0.5s;
}

.caca>span {
    margin: 10px;
    display: block;
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    font-size: 16px;
}

.gris,
.expired {
    background: #a1a1a1;
}

.grisbis {
    background: #717171;
}

.normal {
    background: #339fd0;
    pointer-events: none;
}

.pu-false {
    filter: hue-rotate(288deg);
}

.pu-true {
    filter: hue-rotate(520deg);
}

a {
    color: black;
    text-decoration: none;
}

h1 {
    margin-left: 20px;
    max-width: initial;
}

.cardl {
    max-width: initial;
    margin: 0;
    border-radius: 0;
    color: #dddddd !important;
    padding: 0 20px;
    background: #11141c;
    padding: 0 0 15px 15px;
}
</style>
