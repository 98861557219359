<script setup>
import {
    toRefs,
    ref
} from 'vue'
import {
    useRoute
} from 'vue-router'
import {
    ServerStore
} from '../pinia/Server.js'
const route = useRoute()
const Server = ServerStore()
const props = defineProps({
    data: Object,
    hidename: {
        type: Boolean,
        default: true
    }
})
const {
    data,
    hidename
} = toRefs(props)
</script>

<template>
<RouterLink v-if="data.race.isBoosted == 0 && data.record.name != null" :to="hidename ? '/leaderboard/' + data.race.id: 'times/' + data.race.id" tag="div" class="leaderboardcard">
<div>
    <v-card style="box-shadow:none;height:30px;top:5px;left:5px;width:330px!important;background-color: transparent;">
        <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.race.carClassHash) + '.png'" height="24" style="position:absolute;right:3px;top:3px">
        <img :src="'https://cdn.nightriderz.world/images/website/icons/' + data.race.eventModeId + '.png'" width="36" style="position: absolute;top:-2px;left:-2px">
        <span class="text-truncate" style="display:inline-block;max-width: 260px;font-weight:600;left:32px!important;top:4px;margin-left:32px;margin-top:4px" :title="data.race.name">{{data.race.name}}</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:40px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:2px;left:4px">mdi-timer</v-icon>
        <span style="font-weight:600;position:relative;left:32px!important;top:4px">{{msToHMS(data.record.eventDurationInMilliseconds)}}</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:75px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:2px;left:4px">mdi-account</v-icon>
        <span style="font-weight:600;position:relative;left:32px!important;top:4px">{{data.record.name}}</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:110px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:-6px;left:4px;">mdi-car</v-icon>
        <span class="text-truncate" style="display:inline-block;position:relative;font-weight:600;max-width:290px;left:32px!important;top:4px">{{data.record.manufactor + " " + data.record.model}}</span>
    </v-card>
</div>
</RouterLink>

<RouterLink v-if="data.race.isBoosted == 1" :to="hidename ? '/leaderboard/' + data.race.id: 'times/' + data.race.id" tag="div" class="leaderboardcardboost borderboost">
    <!--<img :src="'https://cdn.nightriderz.world/images/website/moonset/modes/' + data.race.eventModeId + '.png'" width="50">
    <div class="nameboost">
        <span style="font-size: 8px;padding-top: 100px;color:#FFD700">REWARDS BOOSTED</span>
        <b :title="data.race.name" style="margin-top: -6px;">{{data.race.name}}</b>

        <div class="time" :title="data.record.manufactor + ' ' + data.record.model" v-if="data.record.length != 0">
            <v-icon class="timer" icon="mdi-timer-outline"></v-icon>{{msToHMS(data.record.eventDurationInMilliseconds)}}<span v-if="hidename"> by {{data.record.name}}</span>
        </div>

    </div>
    <div class="spacer"></div>
    <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.race.carClassHash) + '.png'" height="26">-->
    <v-tooltip activator="parent" location="bottom" class="custom-tooltip">The rewards of this race are currently boosted by 1.5X on CASH and REP</v-tooltip>
    <div>
    <v-card style="box-shadow:none;height:30px;top:5px;left:5px;width:330px!important;background: transparent;">
        <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.race.carClassHash) + '.png'" height="24" style="position:absolute;right:3px;top:3px">
        <img :src="'https://cdn.nightriderz.world/images/website/moonset/modes/' + data.race.eventModeId + '.png'" width="24" style="position: absolute;top:3px;left:4px">
        <span class="text-truncate" style="display:inline-block;max-width: 260px;font-weight:600;left:32px!important;top:4px;margin-left:32px;margin-top:4px" :title="data.race.name">{{data.race.name}}</span>
        <span style="position: absolute;top: 22px;left: 32px;font-size: 8px;font-weight: 600;color: #DAA520;">RACE BOOSTED</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:40px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:2px;left:4px">mdi-timer</v-icon>
        <span style="font-weight:600;position:relative;left:32px!important;top:4px">{{msToHMS(data.record.eventDurationInMilliseconds)}}</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:75px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:2px;left:4px">mdi-account</v-icon>
        <span style="font-weight:600;position:relative;left:32px!important;top:4px">{{data.record.name}}</span>
    </v-card>
</div>
<div style="position:absolute">
    <v-card style="box-shadow:none;height:30px;top:110px;left:5px;width:330px!important">
        <v-icon style="margin-right:-24px;font-size:24px;top:-6px;left:4px;">mdi-car</v-icon>
        <span class="text-truncate" style="display:inline-block;position:relative;font-weight:600;max-width:290px;left:32px!important;top:4px">{{data.record.manufactor + " " + data.record.model}}</span>
    </v-card>
</div>
</RouterLink>

</template>

<style scoped>
.timer {
    margin-right: 5px;
}

.leaderboardcard {
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
    overflow: hidden;
    background: white;
    width: 340px;
    display: flex;
    background-color: var(--primary-color);
    margin: 10px;
    height: 145px;
}

.ellipsis {
    width: 215px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.leaderboardcardboost {
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
    overflow: hidden;
    background: white;
    width: 340px;
    display: flex;
    background: var(--primary-color);
    margin: 10px;
    height: 145px;
}

.borderboost {
    position: relative;
      box-shadow: inset 0 6px 0 #DAA520; /* Ajoute une "bordure" interne */
}

.name {
    margin-left: 10px;
    line-height: 20px;
}

.nameboost {
    margin-left: 10px;
    margin-top: -10px;
    line-height: 20px;
}

b {
    width: 215px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.time {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.time .material-icons-outlined {
    font-size: 18px;
    margin-right: 5px;
}
.v-tooltip__content {
  background-color: #ffcc00 !important;
  color: #000 !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  padding: 10px !important;
}
</style>
