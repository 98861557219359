# Guide.vue
<script setup>
import { ref } from 'vue'
import controllers from '../configs/controllers.json'
import controllerCard from '../components/controllerCard.vue'
import unsupportedController from '../components/unsupportedController.vue'

const tab = ref(1)

const classAvailability = {
  title: 'Car Class Availability',
  classes: [
    { name: 'E class', level: 'available as soon as you create an account' },
    { name: 'D class', level: 'available at level 12' },
    { name: 'C class', level: 'available at level 24' },
    { name: 'B class', level: 'available at level 36' },
    { name: 'A class', level: 'available at level 48' },
    { name: 'S1 class', level: 'available at level 60' },
    { name: 'S2 class', level: 'available at level 72' },
    { name: 'X class', level: 'available at level 84' }
  ]
}

const partsLevelInfo = {
  title: 'Performance Parts Levels',
  levels: [
    { name: '3★ Pro Parts', level: 'Level 1 (Account creation)' },
    { name: '4★ Ultra Parts', level: 'Level 45' },
    { name: '5★ Elite Parts', level: 'Level 60' }
  ]
}

const horizonPartsInfo = {
  title: 'Horizon Performance Parts',
  description: `Horizon Parts (NIGHTRIDERZ PERFORMANCE) are the best quality performance parts you can obtain on the server. You can drop them only via Horizon Parts' weekend event, or every 5 days of Treasure Hunt.

The upside is its recompensation for their rarity, and their effectiveness: when you install the parts, your car will reach its highest possible potential/performance, meaning that it will be at its peak. However, it's not always the case when applying Horizon parts just to get the highest possible OA in your car, most likely due to a not properly retuned vehicle (ekhm, old OG performance).

Even though they're the best set of performance parts on the server, it sometimes could mean that you have to use a different setup to achieve optimal results, due to the nature of cars and their behavior.`
}

const basicPartsInfo = {
  title: 'Basic Performance Parts',
  description: `Some cars have already a good enough base accel, speed or handling.
Based on that basic information, you have 4 kinds of parts that will help you reach your desired build, check bonuses below:`,
  parts: [
    {
      color: 'blue',
      name: 'Blue parts',
      properties: 'The highest acceleration, lower top speed, weak handling and/or least stability'
    },
    {
      color: 'green',
      name: 'Green parts',
      properties: 'The highest top speed, medium acceleration, weak handling'
    },
    {
      color: 'red',
      name: 'Red parts',
      properties: 'Best handling and stability, lower top speed, weakest acceleration'
    },
    {
      color: 'yellow',
      name: 'Yellow parts',
      properties: 'A mix of everything - balanced speed, acceleration, and handling'
    }
  ]
}

const carsList = [
{
        name: 'Acura RSX',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 0.5,
        notes: 'A decent option in lower classes.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Alfa Romeo 8C Competizione',
        drivetrain: 'RWD',
        nitroStrength: 0
    },
    {
        name: 'Alfa Romeo Brera',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        difficulty: 'Easy'
    },
    {
        name: 'Alfa Romeo Giulia Quadrifoglio',
        classes: 'B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Red/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Aston Martin DB11 / Phantom Edition',
        classes: 'A, S1, S2',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Aston Martin DBS',
        drivetrain: 'RWD',
        nitroStrength: 0
    },
    {
        name: 'Aston Martin One-77',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Yellow/Full HRZ',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Aston Martin V12 Vantage',
        drivetrain: 'RWD',
        nitroStrength: 0
    },
    {
        status: 'Not Retuned',
        name: 'Aston Martin DBS Volante',
        drivetrain: 'RWD',
        nitroStrength: 0
    },
    {
        name: 'Aston Martin Vulcan',
        classes: 'S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Audi A1 Clubsport Quattro',
        classes: 'B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Take it with caution: it may not deliver enough downforce for jumps.',
        setup: 'Speed: Green\nMixed: Blue/Red',
        difficulty: 'Medium/Hard'
    },
    {
        name: 'Audi A3 3.2 Quattro',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/PU',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Green'
    },
    {
        name: 'Audi Quattro 20V',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0,
        notes: 'The Group B rally car reborn.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Audi R8 4.2 FSI Quattro',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed + PU',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Green/Blue'
    },
    {
        status: 'Not Retuned',
        name: 'Audi R8 Coupe 5.2 FSI Quattro',
        drivetrain: 'AWD'
    },
    {
        status: 'Not Retuned',
        name: 'Audi R8 LMS Ultra',
        drivetrain: 'RWD'
    },
    {
        name: 'Audi R8 V10 Performance Coupe',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Blue/Red'
    },
    {
        name: 'Audi RS4',
        classes: 'B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Yellow/Red',
        difficulty: 'Hard'
    },
    {
        name: 'Audi RS6 Avant',
        classes: 'B, A, S1, S2',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Audi S5',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Red'
    },
    {
        name: 'Audi TT RS Coupe',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'Beware of its low top speed. Launch and acceleration is a real neckbreaker.',
        setup: 'Blue + Red',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Bentley Continental Supersports Convertible',
        drivetrain: 'AWD',
        nitroStrength: '??'
    },
    {
        status: 'Not Retuned',
        name: 'Bentley Continental Supersports Coupe',
        drivetrain: 'AWD',
        nitroStrength: '??'
    },
    {
        name: 'BMW 1 Series M Coupe',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'It can prove to be a good allrounder.',
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        name: 'BMW 135i Coupe',
        classes: 'C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: "Poor man's 1M alternative.",
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'BMW 3.0 CSL Gr.5',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'That car loves flat roads. Quite allergic to any sort of jump.',
        setup: 'Any',
        difficulty: 'Hard'
    },
    {
        name: 'BMW i8 Coupe',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'BMW i8 Coupe K.S.',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'BMW M1 Procar [1979]',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'BMW M2 [G87]',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'The M2 does not like any medium+ jumps.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'BMW M3 [E92]',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue/Green',
        difficulty: 'Easy'
    },
    {
        name: 'BMW M3 GTR [E46]',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Green/Yellow',
        difficulty: 'Medium'
    },
    {
        name: 'BMW M3 GTR ALMS [E46]',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'This car does not need any introduction.',
        setup: 'Green/Full HRZ',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'BMW M3 GTS [E92]',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'BMW M3 Sport Evolution',
        classes: 'D, C, B, A',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Speed: Green\nMixed: Red',
        difficulty: 'Medium'
    },
    {
        name: 'BMW M6 Convertible [E64]',
        classes: 'B, A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'A weaker version of the M6, but serves a different purpose.',
        setup: 'Speed: Green\nMixed: Red',
        difficulty: 'Hard'
    },
    {
        name: 'BMW M6 Coupe [E63]',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Red/Green',
        difficulty: 'Medium'
    },
    {
        name: 'BMW M8 Competition',
        classes: 'B, A, S1',
        speciality: 'Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Blue/Red'
    },
    {
        status: 'Not Retuned',
        name: 'BMW Z4 GT3',
        drivetrain: 'RWD'
    },
    {
        name: 'BMW Z4 M Coupe',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Bugatti Bolide',
        classes: 'X class only',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0,
        notes: 'A rather understeery, but easy and simple car to handle in X.',
        setup: 'stock',
        difficulty: 'Easy'
    },
    {
        name: 'Bugatti Chiron Pur Sport',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Better in S2, but does the job in X.',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Bugatti Divo',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Better in X, but does the job in S2.',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Bugatti EB110 SS',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'Another underrated gem.',
        setup: 'Blue/Red',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Bugatti Veyron 16.4',
        classes: 'S2 only',
        drivetrain: 'AWD'
    },
    {
        status: 'Achievement',
        name: 'Bugatti Veyron 16.4 Golden Edition',
        classes: 'S2, X',
        speciality: 'Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Buick Regal GNX',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'A muscle car should not have a V6.',
        setup: 'Blue/Green',
        difficulty: 'Hard'
    },
    {
        name: 'Cadillac CTS-V',
        classes: 'B, A, S1',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'More than enough of juice. But it can be more than enough of the mass.',
        setup: 'B class: Green\nA class: Yellow\nS1: Full HRZ',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Caterham R500 Superlight',
        classes: 'B, A, S1',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Low steering angle, but incredible cornering capabilities.',
        setup: 'Yellow/Full HRZ',
        difficulty: 'Hard'
    },
    {
        name: 'Chevrolet C10 Stepside',
        classes: 'E, D, C, B',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        difficulty: 'Hard'
    },
    {
        name: 'Chevrolet Camaro SS',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Green/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Chevrolet Camaro ZL1',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'It can oversteer, if you really overdo with steering inputs',
        setup: 'Green',
        difficulty: 'Hard'
    },
    {
        name: 'Chevrolet Camaro ZL1 Elite',
        classes: 'X class only',
        speciality: 'Speed/Drag',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'Try to maintain control challenge - difficulty impossible',
        setup: 'stock',
        difficulty: 'Ultra Hard'
    },
    {
        name: 'Chevrolet Chevelle SS',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'All acceleration, no handling.',
        setup: 'G for speed, R for mixed',
        difficulty: 'Very Hard'
    },
    {
        name: 'Chevrolet Cobalt SS',
        classes: 'E, D, C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'FWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Chevrolet Corvette C7 ZR1',
        classes: 'A, S1, S2',
        speciality: 'Mixed/PU',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'Could be more PU focused.',
        setup: 'Yellow',
        difficulty: 'Medium'
    },
    {
        name: 'Chevrolet Corvette Stingray [C2]',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Green/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Chevrolet Corvette Stingray [C8]',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25
    },
    {
        status: 'Not Retuned',
        name: 'Chevrolet Corvette Z06',
        drivetrain: 'RWD'
    },
    {
        status: 'Not Retuned',
        name: 'Chevrolet Corvette Z06 Carbon Limited Ed.',
        drivetrain: 'RWD'
    },
    {
        status: 'Not Retuned',
        name: 'Chevrolet Corvette ZR1 [C6]',
        drivetrain: 'RWD'
    },
    {
        status: 'Exclusive',
        name: 'Chevrolet Corvette ZR1 [Haunted Edition]',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Chevrolet El Camino SS',
        classes: 'D, C, B, A',
        speciality: 'Speed/Pursuit',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'Sickening amount of power.',
        setup: 'Green/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Chrysler Hemi 300C SRT8',
        classes: 'D, C, B, A',
        speciality: 'Speed/Pursuit',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'DeLorean DMC-12',
        classes: 'E, D, C, B',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Low acceleration, requires precise steering input control.',
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Dodge Challenger Concept',
        classes: 'B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Green/Red',
        difficulty: 'Hard'
    },
    {
        name: 'Dodge Challenger R/T',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Green/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Dodge Challenger SRT Hellcat',
        classes: 'A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'Better than the Charger SRT Hellcat in shorter speed tracks.',
        setup: 'Blue/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Dodge Charger R/T',
        classes: 'E, D, C, B, A',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Dodge Charger SRT Hellcat',
        classes: 'A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Go all in with speed, or go home.',
        setup: 'Blue/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Dodge Charger SRT8 Super Bee',
        classes: 'C, B, A',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Any',
        difficulty: 'Ultra Hard'
    },
    {
        name: 'Dodge Viper GTS',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Dodge Viper SRT10',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Green',
        difficulty: 'Medium'
    },
    {
        name: 'Dodge Viper SRT10 ACR',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Any',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Dodge Viper SRT10 ACR [Elite Edition]',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'Ferrari 488 GTB',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Ferrari 488 Pista',
        classes: 'S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Ferrari 512-TR',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'Classy.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Ferrari Enzo',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Ferrari F40',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Very Hard'
    },
    {
        name: 'Ferrari FXX-K Evo',
        classes: 'S2, X',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Currently fastest all-rounder.',
        setup: 'Blue/Red',
        difficulty: 'Easy'
    },
    {
        name: 'Ferrari SF90 Stradale',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Low downforce, be cautious with jumps',
        setup: 'Blue/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Fiat Grande Punto',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 0.25,
        setup: 'Green/Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Ford Capri RS3100',
        classes: 'B, A, S1',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: "The setup you're using, will determine the car's stability.",
        setup: 'Red',
        difficulty: 'Medium/Ultra Hard'
    },
    {
        name: 'Ford Crown Victoria Interceptor',
        classes: 'E, D, C, B, A',
        speciality: 'Speed/Pursuit',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: "Ol' good classic police unit.",
        setup: 'Racing: Green\nPursuit/Drag: Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Ford Escort RS Cosworth',
        classes: 'D, C, B, A, S1',
        speciality: 'Mixed/Tech',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'One of the few technical-ish cars that rely more on acceleration.',
        setup: 'Yellow/Red',
        difficulty: 'Easy'
    },
    {
        name: 'Ford Escort Mk1 RS1600 Group 2',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        setup: 'Green/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Ford F-150 SVT Raptor',
        classes: 'E, D, C, B',
        speciality: 'Mixed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Red'
    },
    {
        name: 'Ford Focus RS',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'PULL UP IN A FORD FOCUS',
        setup: 'Yellow/Green?',
        difficulty: 'Medium'
    },
    {
        name: 'Ford GT',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue/Full HRZ',
        difficulty: 'Hard'
    },
    {
        name: 'Ford GT [2017]',
        classes: 'S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Ford GT40 Mk.I',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Very Hard'
    },
    {
        name: 'Ford Lotus Cortina',
        classes: 'E, D, C, B',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Insanely responsive with its steering.',
        setup: 'Any',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Ford Mustang Boss 302 \'12',
        drivetrain: 'RWD'
    },
    {
        name: 'Ford Mustang Boss 302 \'69',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'The introduction to an average muscle car behavior on the server.',
        setup: 'Green/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Ford Mustang Dark Horse',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'Quite understeery, but predictable. Plenty of power.',
        setup: 'Red/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Ford Mustang GT \'05',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Any'
    },
    {
        status: 'Not Retuned',
        name: 'Ford Mustang RTR-X',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Ford Police Interceptor',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Ford Shelby GT500 Super Snake',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Green'
    },
    {
        name: 'Ford Shelby GT500 \'20',
        classes: 'A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'A bit on the stiffer side.',
        setup: 'Blue/Red'
    },
    {
        name: 'Ford Shelby Terlingua Mustang',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue/Red'
    },
    {
        name: 'Gumpert Apollo Sport',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'One of the easier cars to drive in higher classes.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Hennessey Camaro Exorcist',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Blue in S1, Blue/Red for S2',
        difficulty: 'Very Hard'
    },
    {
        name: 'Hennessey Venom F5',
        classes: 'X class only',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Considered to be one of the hardest cars on the server. Astonishing acceleration.',
        setup: 'stock',
        difficulty: 'Ultra Hard'
    },
    {
        name: 'Hennessey Venom GT',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue in S2, Red for X',
        difficulty: 'Very Hard'
    },
    {
        name: 'Honda Civic CX \'99',
        classes: 'E, D, C, B, A',
        speciality: 'Tech',
        drivetrain: 'FWD',
        nitroStrength: 0,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Honda NSX Type-R',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'Smooth and seriously punchy.',
        setup: 'Yellow/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Honda NSX Type-S',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Yellow/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Honda S2000',
        classes: 'D, C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Hummer H1 Alpha',
        classes: 'E, D, C, B',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: '3 tons of disaster, for police cars and roadblocks.',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        status: 'Exclusive',
        name: 'Hummer H1 Alpha [Santa Edition]',
        classes: 'D, C, B, A',
        speciality: 'Speed/Pursuit/Drag',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'An improved version of H1 Alpha.',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Infiniti G35',
        classes: 'C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'A bit on the heavier side.',
        setup: 'Green',
        difficulty: 'Hard'
    },
    {
        name: 'Infiniti Q60S',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Red/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Jaguar E-Type Lightweight',
        classes: 'B, A, S1',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        setup: 'Blue'
    },
    {
        name: 'Jaguar F-Type SVR',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        notes: 'Excellent launch, magnificent acceleration.',
        setup: 'Red/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Jaguar XJ220',
        classes: 'A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Jaguar XKR',
        classes: ' A, S1',
        speciality: 'Speed',
        drivetrain: 'RWD',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Jeep Grand Cherokee SRT',
        classes: 'C, B, A, S1',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'BIG BRICK ON THE BLOCC',
        setup: 'Mixed: Red\nSpeed: Blue/Green',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Koenigsegg Agera',
        drivetrain: 'RWD'
    },
    {
        status: 'Achievement',
        name: 'Koenigsegg Agera [Golden Edition]',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'Call it an early retune of Agera, but reskinned as different car. For now.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Koenigsegg CCX',
        classes: 'S1, S2, X',
        drivetrain: 'RWD'
    },
    {
        status: 'Not Retuned',
        name: 'Koenigsegg CCXR Edition',
        drivetrain: 'RWD'
    },
    {
        name: 'Koenigsegg Gemera',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'So far, the only single-speed gearbox vehicle on the server.',
        setup: 'S2: Red mainly, a bit of Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Koenigsegg Jesko',
        classes: 'X class only',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Probably one of the best allrounders on the server.',
        setup: 'stock',
        difficulty: 'Medium'
    },
    {
        name: 'Koenigsegg One:1',
        classes: 'S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'One:1 power/weight ratio never fails to deliver.',
        setup: 'Mixed: Red\nSpeed: Green',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Aventador LP 700-4',
        classes: 'S1, S2',
        speciality: 'Mixed/Speed/PU?',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'The very first gen of the Aventador. Less downforce, but does better on straight lines.',
        setup: 'Blue/Red mix',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Aventador LP 770-4 SVJ',
        classes: 'S1, S2, X',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'Surprisingly good at being a allrounder car.',
        setup: 'Blue/Red mix',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Centenario LP 770-4',
        classes: 'S1, S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Green/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Countach LPI 800-4',
        classes: 'S1, S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Functions better as allrounder/speed car, it straight up dies on 7th gear.',
        setup: 'Green/Yellow',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Countach 5000 QV',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'It gets harder to control with greater upgrades.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Hard'
    },
    {
        name: 'Lamborghini Diablo SV',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'The legendary NFS signature car.',
        setup: 'Green/Blue',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Estoque',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Gallardo LP 550-2 VB',
        drivetrain: 'RWD'
    },
    {
        name: 'Lamborghini Gallardo LP 560-4',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        notes: 'More of an all-rounder type of the Gallardo LP 560-4.',
        setup: 'Yellow',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Gallardo LP 560-4 Spyder',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Speed-focused variant of the Gallardo LP 560-4. Furthermore, it has more juice.',
        setup: 'Yellow/Green',
        difficulty: 'Medium'
    },
    {
        name: 'Lamborghini Huracan LP 610-4',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Blue/Red mix'
    },
    {
        name: 'Lamborghini Huracan LP 640-4 Performante',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Blue'
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Miura Concept',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        name: 'Lamborghini Miura SV',
        classes: 'B, A, S1',
        speciality: 'Mixed/PU (?)',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        notes: "The key to success, is to maintain speed with this car. And no, it's not oversteery.",
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Murcielago LP 640',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Murcielago LP 650-4 Roadster',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Murcielago LP 670-4 SV',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Achievement',
        name: 'Lamborghini Murcielago SV [Golden Edition]',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'Shiny.',
        setup: 'Mixed: Red\nSpeed: Blue/Green',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Reventon',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Lamborghini Sesto Elemento',
        classes: 'S1, S2',
        drivetrain: 'AWD',
        notes: 'Big time nimble, versatile, responsive.',
        setup: 'Green/Full HRZ',
        difficulty: 'Easy'
    },
    {
        name: 'Lamborghini Urus LP 650-4',
        classes: 'B, A, S1, S2',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'Real smooth.',
        setup: 'Red/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Lamborghini Veneno LP 750-4',
        classes: 'S1, S2, X',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'One of the greatest allrounders in S2, maybe in S1, too.',
        setup: 'Yellow/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Lancia Delta HF Integrale Evoluzione',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'AWD',
        notes: 'Low top speed.',
        setup: 'Blue/Green',
        difficulty: 'Medium'
    },
    {
        name: 'Lancia LC2',
        classes: 'S2 only',
        speciality: 'Tech',
        drivetrain: 'RWD',
        notes: 'Steering responsive-wise it can feel like a truck, but: high risk, high reward.',
        setup: 'Full HRZ/Red',
        difficulty: 'Ultra Hard'
    },
    {
        name: 'Land Rover Range Rover Sport SVR',
        classes: 'C, B, A, S1',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'Suspension can be on the siffer side. Watch out for the opps.',
        setup: 'Speed: Blue\nMixed: Red',
        difficulty: 'Hard'
    },
    {
        name: 'Lexus IS F',
        classes: 'B, A, S1',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'The Japanese yacht.',
        setup: 'Green',
        difficulty: 'Hard'
    },
    {
        name: 'Lexus IS300',
        classes: 'D, C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Lexus IS350',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Lexus LFA',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'One of the best sounding cars on the server, along with good allrounder performance.',
        setup: 'Any',
        difficulty: 'Medium'
    },
    {
        name: 'Lotus 3-Eleven',
        classes: 'A, S1',
        speciality: 'Tech',
        drivetrain: 'RWD',
        notes: 'Quite low top speed, eats corners like nothing.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Easy'
    },
    {
        name: 'Lotus Elise',
        classes: 'C, B, A',
        speciality: 'Tech',
        drivetrain: 'RWD',
        notes: 'A bit on the sliding side.',
        setup: 'Yellow/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Lotus Emira',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'Simple, viable, predictable and quick.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Easy'
    },
    {
        name: 'Lotus Europa S',
        classes: 'C, B, A',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Low steering angle. Good cornering capabilities.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Lotus Evora',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'Lotus Exige Cup 260',
        classes: 'B, A, S1?',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Marussia B2',
        drivetrain: 'RWD'
    },
    {
        name: 'Maserati GranTurismo MC Stradale',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Any',
        difficulty: 'Medium'
    },
    {
        name: 'Maserati Quattroporte Sport GT S',
        classes: 'C, B, A, S1',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Any',
        difficulty: 'Medium'
    },
    {
        name: 'Mazda 787B',
        classes: 'S2, X',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'The very first X class technical car. Stunning engine sound.',
        setup: 'Tech: Red \nMixed: Blue',
        difficulty: 'Medium/Hard'
    },
    {
        name: 'Mazda Furai',
        classes: 'S1, S2',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'An easier version of 787B, made for S1.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Mazda Mazdaspeed 3',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'FWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mazda MX-5',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Quite enjoyable car to begin a grinding journey with.',
        difficulty: 'Easy'
    },
    {
        name: 'Mazda MX-5 ND',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'Very responsive.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Mazda RX-7 [FC3S]',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        setup: 'Blue',
        difficulty: 'Easy/Hard'
    },
    {
        name: 'Mazda RX-7 [FD3S]',
        classes: 'D, C, B, A, S1',
        speciality: 'Mixed/PU',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'It cannot go any easier with that car.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Mazda RX-7 RZ',
        classes: 'C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Green?',
        difficulty: 'Medium'
    },
    {
        status: 'Exclusive',
        name: 'Mazda RX-7 RZ [Snowman Edition]',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        status: 'Achievement',
        name: 'Mazda RX-7 Veilside Fortune',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: "A bit drifty, but it does not punish you hard if you're cautious.",
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Mazda RX-8',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mazda RX-8 \'09',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        difficulty: 'Medium'
    },
    {
        name: 'McLaren 720S',
        classes: 'S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Blue',
        difficulty: 'Very Hard'
    },
    {
        name: 'McLaren F1',
        classes: 'S1, S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'Things can escalate with various setups.',
        setup: 'Any',
        difficulty: 'Medium/Ultra Hard'
    },
    {
        status: 'Not Retuned',
        name: 'McLaren F1 - Elite Edition',
        classes: 'S1, S2',
        speciality: 'Mixed/PU',
        drivetrain: 'RWD',
        nitroStrength: 1,
        difficulty: 'Medium'
    },
    {
        name: 'McLaren F1 GTR',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        notes: 'Easy to pick up, regardless of your abilities.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'McLaren MP4-12C',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        status: 'Exclusive',
        name: 'McLaren MP4-12C - Braaaiiins Edition',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue/Red',
        difficulty: 'Easy'
    },
    {
        name: 'McLaren P1',
        classes: 'S1, S2, X',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'One of the great allrounders across its available classes.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'McLaren Senna',
        classes: 'S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        status: 'Achievement',
        name: 'McLaren Senna GTR',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'McLaren Speedtail',
        classes: 'S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Mercedes-AMG GTR',
        classes: 'A, S1, S2',
        drivetrain: null,
        nitroStrength: 0.5,
        setup: '???',
        difficulty: 'Medium'
    },
    {
        name: 'Mercedes-Benz 190 E Evolution II',
        classes: 'D, C, B, A',
        speciality: 'Mixed/PU',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Blue/Full HRZ',
        difficulty: 'Easy'
    },
    {
        name: 'Mercedes-Benz CLK 500 [C209]',
        classes: 'C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'Rather on the heavy side, but far easier than its faster brothers.',
        setup: 'Green/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mercedes-Benz CLK LM',
        classes: 'S1, S2',
        speciality: 'Tech',
        drivetrain: 'RWD',
        notes: 'No longer as violent.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mercedes-Benz CLS 63 AMG [C219]',
        classes: 'B, A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Green/Red',
        difficulty: 'Medium/Hard'
    },
    {
        name: 'Mercedes-Benz G63 AMG',
        classes: 'C, B, A, S1',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: 'A massive gamecube[63].',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Mercedes-Benz SL65 AMG Black Series',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed/Pursuit',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'Heavier than the SLR 722, but equipped with more torque.',
        setup: 'Red/Green',
        difficulty: 'Medium'
    },
    {
        name: 'Mercedes-Benz SLR McLaren 722 Edition',
        classes: 'A, S1, S2',
        speciality: 'Speed/Drag',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'A speed boat that will leave any speed car enjoyer a smile on their face.',
        setup: 'Red',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Mercedes-Benz SLR McLaren Stirling Moss',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: '???',
        difficulty: 'Easy'
    },
    {
        name: 'Mercedes-Benz SLS AMG',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        name: 'Mercury Cougar',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Speed/Drag',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'Galatical amount of acceleration, "moon gravity" type of downforce.',
        setup: 'Red/Green',
        difficulty: 'Very Hard'
    },
    {
        name: 'Mitsubishi Eclipse GS-T',
        classes: 'D, C, B, A',
        speciality: 'Speed',
        drivetrain: 'FWD',
        nitroStrength: 0.75,
        notes: 'Low downforce with lower classes.',
        setup: 'Green/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Mitsubishi Eclipse GS-T [Elite Edition]',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Tech/PU',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'The classic from OG era. It still does wonders, cannot go wrong with it at all.',
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Mitsubishi Eclipse GT',
        classes: 'D, C, B, A',
        speciality: 'Speed',
        drivetrain: 'FWD',
        nitroStrength: 0.75,
        setup: '???',
        difficulty: 'Medium'
    },
    {
        name: 'Mitsubishi Lancer Evolution VIII',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        notes: 'More speed focused, does it well on high-speed corners.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mitsubishi Lancer Evolution IX MR-Edition',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'More accel/handling focused.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Mitsubishi Lancer Evolution X',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'NFS World BFH SUV',
        classes: 'E, D, C, B',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        notes: "Essentialy it's the same vehicle, but with different looks.",
        setup: 'Red/Green',
        difficulty: 'Hard'
    },
    {
        name: 'NFS World Speed Rabbit',
        classes: 'E, D, C, B',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Red/Green',
        difficulty: 'Hard'
    },
    {
        name: 'Nissan 200SX [S14]',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan 240SX [S13]',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan 350Z [Z33]',
        classes: 'D, C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        setup: 'Blue',
        nitroStrength: 0.75,
        difficulty: 'Hard'
    },
    {
        name: 'Nissan 370Z [Z34]',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        setup: 'Blue/Red',
        nitroStrength: 0.25
    },
    {
        name: 'Nissan 370Z Roadster',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'One of the sleepers in A/S1',
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan 400Z',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan Fairlady 240ZG',
        classes: 'E, D, C, B',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: '---BBB, Blue for higher class',
        difficulty: 'Hard'
    },
    {
        name: 'Nissan GT-R [R35]',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        setup: 'Green/Red',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Nissan GT-R SpecV [R35]',
        drivetrain: 'AWD'
    },
    {
        status: 'Not Retuned',
        name: 'Nissan Nismo R34 GT-R Z-tune',
        classes: 'A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        setup: 'Blue'
    },
    {
        status: 'Achievement',
        name: 'Nissan R390 GT1',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        setup: 'Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan Silvia [S15]',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        setup: 'Blue'
    },
    {
        status: 'Achievement',
        name: 'Nissan Silvia [S15] R3 Spec',
        classes: 'A, S1, S2',
        speciality: 'Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'The vehicle is track/setup dependent, but is primarily focused to be swift on corners.',
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        name: 'Nissan Skyline 2000 GT-R [C10]',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'A slept on Japanese classic.',
        setup: 'Blue/Red mix',
        difficulty: 'Hard'
    },
    {
        status: 'Achievement',
        name: 'Nissan Skyline GT-R LM [R33]',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'Low top speed, better to invest into speed gaining parts on speed sections.',
        setup: 'Green/Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan Skyline GT-R [R32]',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Green/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan Skyline GT-R V-Spec [R33]',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Yellow / Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Nissan Skyline GT-R V-Spec [R34]',
        classes: 'C, B, A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        notes: 'Blud was successful in Japanese touring car racing events during its prime. You can\'t go wrong with it. However, bear with minor understeer, due to AWD nature.',
        setup: 'C/B class: Blue\nA+ class: Yellow',
        difficulty: 'Medium/Hard'
    },
    {
        name: 'Pagani Huayra',
        classes: 'S1, S2, X',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.75,
        notes: 'The heavier, but focused on straights version of Huayra.',
        setup: 'Green/Red',
        difficulty: 'Hard'
    },
    {
        name: 'Pagani Huayra BC',
        classes: 'S1, S2, X',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        notes: 'The lighter version of Huayra.',
        setup: 'Blue/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Pagani Utopia',
        classes: 'S1, S2, X',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'An improved version of the Huayra.',
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Pagani Zonda Cinque',
        classes: 'A, S1, S2',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Green generally, Blue in S1',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Pagani Zonda F',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'Quite low top speed. Brutal launch.',
        setup: '???',
        difficulty: 'Easy'
    },
    {
        status: 'Achievement',
        name: 'Pagani Zonda F [Golden Edition]',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        notes: 'Surprising on corners.',
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Pagani Zonda F Roadster',
        classes: 'A, S1, S2',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: '???',
        difficulty: 'Easy'
    },
    {
        name: 'Peugeot 206 RC',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 0.25,
        notes: 'Funky, fun car.',
        setup: '???',
        difficulty: 'Easy'
    },
    {
        name: 'Plymouth Hemi Cuda',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'In torque we trust.',
        setup: 'Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Plymouth Road Runner',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'The statement from above would like to say hello.',
        setup: 'Red',
        difficulty: 'Hard'
    },
    {
        name: 'Polestar 1',
        classes: 'B, A, S1',
        speciality: 'Speed/PU/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        notes: 'A heavy Swedish/Chinese vehicle that does wonders on Team Escapes.',
        setup: 'Blue/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Pontiac Firebird Formula',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech?',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'One of the easier muscle cars to handle.',
        setup: 'Any',
        difficulty: 'Hard/Very Hard'
    },
    {
        name: 'Pontiac GTO \'65',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Mixed: Red\nSpeed: Blue',
        difficulty: 'Very Hard'
    },
    {
        name: 'Pontiac Solstice GXP',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'Very versatile.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Porsche 718 Cayman GTS',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: "May feel like it's about to lose control, but it's not like that at all.",
        setup: 'Yellow/Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Porsche 911 Carrera RSR 3.0',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        notes: 'One of the "tail-happy" technical cars. Phenomenal steering.',
        setup: 'Green/Red',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 911 Carrera S',
        drivetrain: 'AWD',
        nitroStrength: 1
    },
    {
        name: 'Porsche 911 Carrera S [993]',
        classes: 'B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'The introduction to "Very Hard" type of vehicles.',
        setup: 'Blue',
        difficulty: 'Very Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 911 GT2',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 911 GT2 [997]',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'Porsche 911 GT2 RS [991]',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        setup: 'Blue/Full HRZ',
        difficulty: 'Easy'
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 911 GT3 RS',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 911 GT3 RS 4.0',
        drivetrain: 'RWD',
        nitroStrength: 1
    },
    {
        name: 'Porsche 911 Turbo',
        classes: 'A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'One of the heavier Porsches.',
        setup: 'Green/Red',
        difficulty: 'Medium'
    },
    {
        name: 'Porsche 914-6 GT',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0,
        notes: 'Relies a bit more on accel zones than on corners, but still does the job.',
        setup: 'Any',
        difficulty: 'Easy'
    },
    {
        name: 'Porsche 918 RSR Concept',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        setup: 'Green/Blue/Full HRZ',
        difficulty: 'Medium'
    },
    {
        status: 'Not Retuned',
        name: 'Porsche 959',
        drivetrain: 'AWD'
    },
    {
        name: 'Porsche Boxster Spyder',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        notes: 'Lack of attention will definitely punish you.',
        setup: 'Blue/Full HRZ',
        difficulty: 'Hard'
    },
    {
        status: 'Not Retuned',
        name: 'Porsche Carrera GT',
        drivetrain: 'RWD'
    },
    {
        status: 'Achievement',
        name: 'Porsche Carrera GT [Golden Edition]',
        classes: 'A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Blue/Full HRZ',
        difficulty: 'Very Hard'
    },
    {
        status: 'Exclusive',
        name: 'Porsche Cayenne Turbo S',
        classes: 'C, B, A, S1',
        speciality: 'Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Green/Red',
        difficulty: 'Hard'
    },
    {
        name: 'Porsche Cayman S',
        classes: 'C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        setup: 'Blue'
    },
    {
        name: 'Porsche Panamera Turbo',
        classes: 'B, A, S1, S2',
        speciality: 'Speed',
        drivetrain: 'AWD',
        nitroStrength: 1,
        setup: 'Red/Green',
        difficulty: 'Medium'
    },
    {
        name: 'Porsche Taycan Turbo Sport',
        classes: 'B, A, S1',
        speciality: 'Speed/Pursuit',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        notes: 'Bulky, in terms of mass. Possibly the fastest launching car on the server.',
        setup: 'Red/Full HRZ',
        difficulty: 'Hard'
    },
    {
        name: 'Renault Clio V6',
        classes: 'C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: "It's on oversteery side.",
        setup: 'Blue'
    },
    {
        status: 'Achievement',
        name: 'Renault Espace F1',
        classes: 'S1, S2',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        setup: 'Blue/Full HRZ'
    },
    {
        name: 'Renault Megane 3 R.S. 275 Trophy-R',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'A bit more versatile version of the third gen Megane.',
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Renault Megane 3 R.S',
        classes: 'D, C, B, A',
        speciality: 'Mixed/Speed',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'Less handling, but more speed.',
        setup: 'Red/Green',
        difficulty: 'Medium'
    },
    {
        name: 'Renault Megane 4 R.S',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 0.25,
        setup: 'Yellow/Full HRZ',
        difficulty: 'Medium'
    },
    {
        name: 'Scion tC',
        speciality: 'Mixed/Speed',
        drivetrain: 'FWD',
        nitroStrength: 0.5
    },
    {
        name: 'Seat Leon Cupra',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'An underrated hatchback.',
        setup: 'Red/Blue',
        difficulty: 'Medium/Hard'
    },
    {
        name: 'Shelby Cobra Daytona Coupe',
        classes: 'B, A, S1',
        speciality: 'Speed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        notes: 'Much wheelspin at start. Much speed.',
        setup: 'Green/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Shelby Cobra 427 S/C',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        notes: 'You do not want that car to take turns on 1st gear. At all.',
        setup: 'Corners: Red\nMixed/Speed tracks: Green',
        difficulty: 'Ultra Hard'
    },
    {
        name: 'Shelby GT 500 \'67 + RB26 variant',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Subaru BRZ',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Subaru Impreza WRX STI',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.25,
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Subaru Impreza WRX STI [Hatchback]',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Yellow',
        difficulty: 'Medium'
    },
    {
        name: 'Toyota Celica GT-Four RC \'92',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'AWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Toyota Corolla GT-S [AE86]',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Medium'
    },
    {
        name: 'Toyota MR2',
        classes: 'C, B, A, S1',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.25,
        notes: 'Once known to be easily picked up in its OG form, now it\'s the total opposite.',
        setup: 'Blue',
        difficulty: 'Very Hard'
    },
    {
        name: 'Toyota Supra',
        classes: 'C, B, A, S1, S2',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        notes: 'The more powerful it gets, the harder it gets to control.',
        setup: 'Green/Red',
        difficulty: 'Very Hard'
    },
    {
        name: 'Toyota Supra GR',
        classes: 'B, A, S1, S2',
        speciality: 'Mixed',
        drivetrain: 'RWD',
        nitroStrength: 0.5,
        setup: 'Blue',
        difficulty: 'Easy'
    },
    {
        name: 'Vauxhall Monaro VXR',
        classes: 'B, A, S1',
        speciality: 'Mixed/Speed',
        drivetrain: 'RWD',
        nitroStrength: 1,
        setup: 'Green/Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Volkswagen Golf Mk1 GTI',
        classes: 'E, D, C, B, A',
        speciality: 'Mixed/Tech',
        drivetrain: 'FWD',
        nitroStrength: 0,
        notes: 'The real (shitbox) deal.',
        setup: 'Blue',
        difficulty: 'Hard'
    },
    {
        name: 'Volkswagen Golf R32',
        drivetrain: 'AWD',
        nitroStrength: 0.75,
        setup: 'Any?',
        difficulty: 'Medium'
    },
    {
        name: 'Volkswagen Golf VII GTI Clubsport',
        classes: 'C, B, A, S1',
        speciality: 'Mixed/PU',
        drivetrain: 'FWD',
        nitroStrength: 1,
        notes: 'The fastest FWD car on the server.',
        setup: 'Yellow/Full HRZ',
        difficulty: 'Easy'
    },
    {
        name: 'Volkswagen Scirocco',
        classes: 'D, C, B, A',
        speciality: 'Mixed',
        drivetrain: 'FWD',
        nitroStrength: 0,
        setup: 'Blue',
        difficulty: 'Medium'
    }
]

const faqItems = [
  {
    question: 'What is the best car?',
    answer: `There are plenty of very good cars in each class, every mentioned car here offers something else compared to the other. At this point, try to figure out what you need for specific tracks (check Q4 for that). 

TL;DR - do your own research, and a bit of testing.`
  },
  {
    question: 'At what level do I unlock the performance parts?',
    answer: `Level 1 = you unlock 3* Pro Parts, as soon as you create your account,
Level 45 = you unlock 4* Ultra Parts,
Level 60 = you unlock 5* Elite parts`
  },
  {
    question: 'How do the performance builds work?',
    answer: `Remember the performance parts that have different colours? Each of them offers a bonus towards something. For example:
Blue: Acceleration bonus: lower top speed, the highest acceleration, weak handling and/or least stability
Green: Top speed bonus: the highest top speed, mid acceleration, weak handling
Red: Handling bonus: lower top speed, weakest acceleration, the best handling /and stability
Yellow: a bit of everything`
  }
]

const credits = [
  { name: 'MikaArruda', discord: 'mika26b' },
  { name: 'Atom Bomb', discord: '.atombomb.' },
  { name: 'TTcreepyLT', discord: 'fainas_15' },
  { name: 'Sylver', discord: '.sylver.' },
  { name: 'Curryrice', discord: 'noodlecurryricw' }
]
</script>

<template>
    <v-app-bar :elevation="2">
        <v-icon icon="mdi-frequently-asked-questions"></v-icon>
        <v-app-bar-title>Guides</v-app-bar-title>
        <v-spacer></v-spacer>
        
        <a href="https://discord.gg/XJavNVH" target="_blank">
            <v-chip prepend-icon="mdi-discord" label>#need-help</v-chip>
        </a>

        <template v-slot:extension>
            <v-tabs v-model="tab">
                <v-tab value="1">SUPPORTED CONTROLLERS</v-tab>
                <v-tab value="2">UNSUPPORTED CONTROLLERS</v-tab>
                <v-tab value="3">THIRD PARTY SOFTWARES</v-tab>
                <v-tab value="4">PERFORMANCE PARTS & CARS</v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
    
    <div class="guide">
        <v-window v-model="tab">
            <v-window-item value="1">
                <p>Here is the list of controllers that work without third-party software. Be sure to plug in before starting the game.</p>

                <div class="grid">
                    <controllerCard v-for="controller in controllers" :id="controller.image_url" :data="controller"/>
                </div>
            </v-window-item>

            <v-window-item value="2">
                <unsupportedController />
            </v-window-item>

            <v-window-item value="3">
                <v-alert type="success" variant="tonal" title="Drift">
                    <span><b>Freeroam ONLY</b><br><small>Remember to restart your game to completely disable the behavior change.</small></span>
                </v-alert>

                <v-alert type="success" variant="tonal" title="Camera" />
                <v-alert type="success" variant="tonal" title="Vinyl" />
                <v-alert type="success" variant="tonal" title="ENB" />

                <v-alert type="error" variant="tonal" title="GMZ" />
                <v-alert type="error" variant="tonal" title="WallHack" />
                <v-alert type="error" variant="tonal" title="TankMod" />
                <v-alert type="error" variant="tonal" title="Speedhack" />
                <v-alert type="error" variant="tonal" title="GhostMods" />
                <v-alert type="error" variant="tonal" title="Car Changer" />
                <v-alert type="error" variant="tonal" title="Auto-Finish" />
                <v-alert type="error" variant="tonal" title="Auto-Start" />
                <v-alert type="error" variant="tonal" title="Auto-Pilot" />
                <v-alert type="error" variant="tonal" title="Kick Race Player" />
                <v-alert type="error" variant="tonal" title="PowerUps Hack" />
                <v-alert type="error" variant="tonal" title="P2P Lags" />
                <v-alert type="error" variant="tonal" title="Treasure Hunters Hack" />
                <v-alert type="error" variant="tonal" title="Cash Hack" />
            </v-window-item>

            <v-window-item value="4">
                <div class="performance-parts">
                    <!-- Car Class Availability -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            {{ classAvailability.title }}
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="(classItem, index) in classAvailability.classes"
                                    :key="index"
                                    :title="classItem.name"
                                    :subtitle="classItem.level"
                                >
                                    <template v-slot:prepend>
                                        <v-icon icon="mdi-car-sports"></v-icon>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <!-- Parts Level Info -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            {{ partsLevelInfo.title }}
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="(level, index) in partsLevelInfo.levels"
                                    :key="index"
                                    :title="level.name"
                                    :subtitle="level.level"
                                >
                                    <template v-slot:prepend>
                                        <v-icon icon="mdi-star"></v-icon>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <!-- Horizon Parts Section -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            {{ horizonPartsInfo.title }}
                        </v-card-title>
                        <v-card-text>
                            <p class="text-body-1" style="white-space: pre-line">{{ horizonPartsInfo.description }}</p>
                        </v-card-text>
                    </v-card>

                    <!-- Basic Parts Section -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            {{ basicPartsInfo.title }}
                        </v-card-title>
                        <v-card-text>
                            <p class="text-body-1 mb-4">{{ basicPartsInfo.description }}</p>
                            
                            <v-divider class="mb-4"></v-divider>
                            
                            <div class="parts-grid">
                                <v-card
                                    v-for="(part, index) in basicPartsInfo.parts"
                                    :key="index"
                                    :color="part.color"
                                    variant="tonal"
                                    class="part-card"
                                >
                                    <v-card-text>
                                        <div class="text-h6 mb-2">{{ part.name }}</div>
                                        <p class="text-body-1">{{ part.properties }}</p>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>

                    <!-- Cars List Section -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            Available Cars
                        </v-card-title>
                        <v-card-text>
                            <v-table style="border-radius: 4px;">
                                <thead>
                                    <tr>
                                        <th>Car</th>
                                        <th>Classes</th>
                                        <th>Speciality</th>
                                        <th>Drivetrain</th>
                                        <th>N2O</th>
                                        <th>Setup</th>
                                        <th>Difficulty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(car, index) in carsList" :key="index">
                                        <td>{{ car.name }}</td>
                                        <td>{{ car.classes }}</td>
                                        <td>{{ car.speciality }}</td>
                                        <td>{{ car.drivetrain }}</td>
                                        <td>{{ car.nitroStrength }}</td>
                                        <td>{{ car.setup || '—' }}</td>
                                        <td>{{ car.difficulty }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-card-text>
                    </v-card>

                    <!-- FAQ Section -->
                    <v-card class="mb-6" style="background-color:#11141c;border-radius:8px">
                        <v-card-title class="text-h5">
                            Frequently Asked Questions
                        </v-card-title>
                        <v-card-text>
                            <v-expansion-panels>
                                <v-expansion-panel
                                    v-for="(item, index) in faqItems"
                                    :key="index"
                                >
                                    <v-expansion-panel-title>
                                        {{ item.question }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <p class="text-body-1" style="white-space: pre-line">{{ item.answer }}</p>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>

                    <!-- Credits Section -->
                    <v-card style="background: #11141c;border-radius: 6px;">
                        <v-card-title class="text-h5">
                            Credits
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="(credit, index) in credits"
                                    :key="index"
                                    :title="credit.name"
                                    :subtitle="`Discord: ${credit.discord}`"
                                >
                                    <template v-slot:prepend>
                                        <v-icon icon="mdi-account"></v-icon>
                                    </template>
                                </v-list-item>
                            </v-list>
                            <v-divider class="my-3"></v-divider>
                            <p class="text-body-1 mt-2">Shoutout to: Speedou</p>
                        </v-card-text>
                    </v-card>
                </div>
            </v-window-item>
        </v-window>
    </div>
</template>

<style scoped>
.v-tabs {
    height: initial;
}

.guide {
    margin: 20px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.performance-parts {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.parts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
}

.part-card {
    height: 100%;
}

.v-list {
    background: transparent;
}

.text-h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
}

.v-table {
    margin-top: 1rem;
}
</style>

<style>
.v-alert {
    margin: 10px 0px!important;
    display: flex!important;
    justify-content: initial!important;
    align-items: initial!important;
}

.v-chip {
    cursor: pointer!important;
}

.v-expansion-panel-text__wrapper {
    padding: 16px;
}
</style>