<script setup>
import leaderboardCard from "../components/leaderboardCard.vue"
import SKleaderboardCard from "../components/skeletons/SKleaderboardCard.vue"
import {
    getCurrentInstance,
    ref,
    computed,
    watch,
    toRefs
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

const props = defineProps({
    data: String
    })
    const {data} = toRefs(props)
const {
    proxy
} = getCurrentInstance()

const inputsearch = ref("")
var races = ref([])

function search(e) {
    inputsearch.value = e
}

callCards()

async function callCards() {
    const res = await proxy.$api({
        serviceName: "leaderboard",
        methodName: "GetCardsDriver",
        parameters: [data.value]
    })
    races.value = res
    document.querySelector('.skeleton').style.display = "none"
}

</script>

<template>

<div class="leaderboards">
    <div class="grid skeleton">
        <SKleaderboardCard v-for="i in 40" />
    </div>

    <div class="grid">
        <leaderboardCard :key="race.id" :data="race" v-for="race in races" v-if="races.length" :hidename="false"/>
        <div v-else>Sorry, no matching race.</div>
    </div>
</div>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}

.button {
    box-shadow: none;
    background: 0 0;
    border: none;
    border-radius: 6px;
    position: relative;
    height: 36px;
    padding: 0 16px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
}

.nav {
    background-color: var(--secondary-color);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 10px;
    padding: 5px;
}

.containernav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.button:hover {
    background: var(--quaternary-color);
    color: #f1f2f3;
}

.button.router-link-active {
    color: white !important;
    border-left: 2px solid white;
    background: var(--tertiary-color);
}

.header {
    display: flex;
    align-items: center;
    background: var(--primary-color);
}
</style>
